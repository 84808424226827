import React from 'react';
import MainLayout from '../layouts/mainLayout';

export default () => {
  return (
    <MainLayout withTitle>
      <div className="features-wrapper">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-2">&nbsp;</div>
                <div className="col-8">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">Support</h2>
                    <p>
                      If you need any help or advice please contact us. We'd
                      love to help!
                    </p>
                    <p>
                      Email:{' '}
                      <a href="mailto:info@feedmyfurbaby.co.nz">
                        info@feedmyfurbaby.co.nz
                      </a>
                    </p>
                    <p>Phone: 09 88 MYPET (09 886 9738)</p>
                    <p>Facebook: @myfurbabynz</p>
                    <p>Or contact us using our webchat.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
